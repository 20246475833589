import React, { useState } from "react"
import { Link } from "gatsby"
import useWindowSize from "../Hooks/UseWindowSize"
import { globalHistory as history } from "@reach/router"
import "./header.scss"
import Logo from "../../assets/img/logo-text.png"
import Button from "../Button"

export default (props) => {
  const { location, navigate } = history
  const [nav, setNav] = useState(false)
  const [industriesMenu, setIndustriesMenu] = useState(false)
  const [dropdown, setDropdown] = useState({})

  const size = useWindowSize()

  const toggleDropdown = (name) => {
    setDropdown({ ...dropdown, [name]: !dropdown[name] })
  }

  const isActive = ({ isPartiallyCurrent, location }) => {
    return (
      isPartiallyCurrent &&
      (() => toggleDropdown(location.pathname.split("/")[1]),
      { className: "dropdown__link dropdown__link--active" })
    )
  }
  React.useEffect(() => {
    toggleDropdown(location.pathname.split("/")[1])
  }, [])
  React.useEffect(() => {
    if (nav === true) {
      document.body.classList.add("hideOverflow")
    } else {
      document.body.classList.remove("hideOverflow")
    }
  })
  return (
    <header className="header">
      <div className="header__mobilebar">
        <Link to="/">
          <img src={Logo} alt="Logo" className="header__logo" />
        </Link>
        <button
          onClick={() => setNav(!nav)}
          className={`header__navOpener
        ${nav ? "header__navOpener--opened" : ""}`}
          aria-label={nav ? "Close menu" : "Open menu"}
        ></button>
      </div>

      <nav
        className={`header__nav
        ${nav ? "header__nav--opened" : ""}
        ${size.width < 1023 ? "header__nav--mobile" : ""}`}
      >
        <div className="nav__container">
          <div
            className={`nav__link nav__dropdown dropdown ${
              dropdown["software-licensing"] ? "nav__dropdown--active" : ""
            }`}
          >
            <button
              className="dropdown__opener"
              onClick={() =>
                size.width < 1150 && toggleDropdown("software-licensing")
              }
            >
              Software Licensing
            </button>
            <div className="dropdown__menu">
              <Link
                to="/software-licensing/software-licensing/"
                className="dropdown__link"
                getProps={isActive}
              >
                Software Licensing
              </Link>
              <Link
                to="/software-licensing/floating-licenses/"
                className="dropdown__link"
                getProps={isActive}
              >
                Floating Licenses
              </Link>
              <Link
                to="/software-licensing/metered-licensing/"
                className="dropdown__link"
                getProps={isActive}
              >
                Metered Licensing
              </Link>
              <Link
                to="/software-licensing/user-based-licensing/"
                className="dropdown__link"
                getProps={isActive}
              >
                User-based licensing
              </Link>
              <Link
                to="/software-licensing/node-locking/"
                className="dropdown__link"
                getProps={isActive}
              >
                Node locking
              </Link>
            </div>
          </div>
          <div
            className={`nav__link nav__dropdown dropdown 
            ${dropdown["api"] ? "nav__dropdown--active" : ""}
              `}
          >
            <button
              className="dropdown__opener"
              onClick={() => size.width < 1150 && toggleDropdown("api")}
            >
              API
            </button>
            <div className="dropdown__menu">
              <Link
                to="/api/"
                className="dropdown__link"
                getProps={() =>
                  location.pathname === "/api/"
                    ? { className: "dropdown__link dropdown__link--active" }
                    : null
                }
              >
                General API
              </Link>
              <Link
                to="/api/c-plus-plus/"
                className="dropdown__link"
                getProps={isActive}
              >
                C++
              </Link>
              <Link
                to="/api/dotnet-csharp/"
                className="dropdown__link"
                getProps={isActive}
              >
                .NET
              </Link>
              <Link
                to="/api/java/"
                className="dropdown__link"
                getProps={isActive}
              >
                Java
              </Link>
              <Link
                to="/api/view-all/"
                className="dropdown__link"
                getProps={isActive}
              >
                View All
              </Link>
            </div>
          </div>
          <Link
            to="/pricing/"
            className="nav__link"
            activeClassName="nav__link--active"
          >
            Pricing
          </Link>
          <div
            className={`nav__link nav__dropdown dropdown ${
              dropdown["industries"] ? "nav__dropdown--active" : ""
            }`}
          >
            <button
              className="dropdown__opener"
              onClick={() => size.width < 1150 && toggleDropdown("industries")}
            >
              Industries
            </button>
            <div className="dropdown__menu">
              <Link
                to="/industries/enterprise/"
                className="dropdown__link"
                getProps={isActive}
              >
                Enterprise & Industry
              </Link>
              <Link
                to="/industries/education/"
                className="dropdown__link"
                getProps={isActive}
              >
                Education
              </Link>
              <Link
                to="/industries/gaming-vr/"
                className="dropdown__link"
                getProps={isActive}
              >
                Gaming & VR
              </Link>
              <Link
                to="/industries/isv/"
                className="dropdown__link"
                getProps={isActive}
              >
                ISVs and Start-ups
              </Link>
              {/* <Link to="/industries/education" className="dropdown__link" 
              getProps={isActive}>
                Education
        </Link> */}
            </div>
          </div>

          <a
            href="https://docs.licensespring.com"
            className="nav__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Docs
          </a>
          <Link
            to="/blog/"
            className="nav__link"
            activeClassName="nav__link--active"
          >
            Blog
          </Link>
          <div
            className={`nav__link--login nav__dropdown dropdown ${
              dropdown["login"] ? "nav__dropdown--active" : ""
            }`}
          >
            <button
              className="dropdown__opener"
              onClick={() => size.width < 1150 && toggleDropdown("login")}
            >
              Log in
            </button>
            <div className="dropdown__menu">
              <a
                href="https://saas.licensespring.com/login"
                className="dropdown__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vendor Platform
              </a>
              <a
                href="https://users.licensespring.com/login"
                className="dropdown__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                End-User Platform
              </a>
            </div>
          </div>
          <Button
            to="/get-started/"
            color="blue"
            label="Open trial account"
            className="nav__openTrial"
            shadow
            disclaimer
          />
        </div>
      </nav>
    </header>
  )
}
