import React, { useState } from "react"
import "./Accordion.css"

const AccordionItem = ({ item, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`accordion-item ${isActive ? "active" : ""}`}
    >
      <div
        className={`accordion-header ${isActive ? "active" : ""}`}
        dangerouslySetInnerHTML={{ __html: item.title }}
      ></div>
      {isActive && (
        <div
          dangerouslySetInnerHTML={{ __html: item.content }}
          className="accordion-content"
          onClick={(e) => e.stopPropagation()}
        ></div>
      )}
    </div>
  )
}

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className="accordion">
      {items?.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            item={item}
            isActive={activeIndex === index}
            onClick={() => handleClick(index)}
          ></AccordionItem>
        )
      })}
    </div>
  )
}

export default Accordion
