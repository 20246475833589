import React from "react"
import PropTypes from "prop-types"

import "./TextWithImage.scss"

const TextWithImage = ({ side, width, url, alt, caption, html }) => {
  return (
    <div className={`textWithImage`}>
      <div
        className={`image ${side === "left" ? "left" : "right"}`}
        style={{ width: `${width}%` }}
      >
        <img src={url} alt={alt} />
        <div className="caption">{caption}</div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
    </div>
  )
}

TextWithImage.propTypes = {
  side: PropTypes.oneOf(["left", "right"]),
  width: PropTypes.number,
  url: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TextWithImage
