import "./src/assets/styles/common.scss"
import React from "react"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"

import { linkResolver } from "./src/utils/linkResolver"
import BlogTemplate from "./src/templates/blogpost.js"
import PageTemplate from "./src/templates/page.js"

export const wrapRootElement = (props) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.PRISMIC_REPOSITORY,
          linkResolver,
          componentResolver: componentResolverFromMap({
            licensespring_post: BlogTemplate,
            page: PageTemplate,
          }),
        },
      ]}
    >
      {props.element}
    </PrismicPreviewProvider>
  )
}
export const wrapPageElement = () => {
  console.log(window.location)
  if (
    window.location.pathname == "/get-started/:button" ||
    window.location.pathname == "/get-started/:button/"
  ) {
    window.location.replace(window.location.origin + "/get-started/")
  }

  if (
    window.location.pathname ==
    "/software-licensing/node-locking/https://https://"
  ) {
    window.location.replace(
      window.location.origin + "/software-licensing/node-locking/"
    )
  }
}
