import React, { useEffect } from "react"
import { globalHistory } from '@reach/router'
import Header from "../Header"
import Footer from "../Footer"

export default ({ children, ...props }) => {
    /*
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer.push({ event: 'optimize.activate' })
        }
    }, [])
    */
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || []
           window.dataLayer.push({ event: 'gatsby-route-change' })
            
        }
    }, []);
    return (
        <>
            <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-58MJ95T" height="0" width="0" style="display: none; visibility: hidden" aria-hidden="true"></iframe>`}</noscript>
            <Header />
            <main {...props}>
                {children}

            </main>
            <Footer />
        </>

    )
}