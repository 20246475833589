import React from "react"

import Android from "../../assets/img/homepage/apps/android.svg"
import Apple from "../../assets/img/homepage/apps/apple.svg"
import Cpp from "../../assets/img/homepage/apps/cpp.svg"
import Linux from "../../assets/img/homepage/apps/linux.svg"
import Swift from "../../assets/img/homepage/apps/swift.svg"
import Windows from "../../assets/img/homepage/apps/windows.svg"
import Java from "../../assets/img/homepage/apps/java.svg"

import ArrowLink from "../../components/ArrowLink"

import "./apps.scss"

export default props => (
  <section className="appsComponent">
    <h2>
      {props.title
        ? props.title
        : "SDKs and Web Hooks for all Development Environments"}
    </h2>
    <p>
      {props.content
        ? props.content
        : "LicenseSpring provides SDKs for all major platforms including Mac, Windows, Linux, Android and iOS. If you are developing using C++, VBA, NET/C#, Swift/Objective-C or Java, you can easily add LicenseSpring to your project by using our SDK."}
    </p>
    <div className="arrowHolder">
      <ArrowLink
        to="https://docs.licensespring.com/docs/sdks"
        label="View our SDKs"
        anchor={true}
      />
      <ArrowLink
        to="https://docs.licensespring.com/docs/getting-started"
        label="View our Implementation guides"
        anchor={true}
      />
    </div>
    <div className="appsCards">
      <div className="appsCards__card">
        <img src={Android} alt="Android logo" />
        <span>License your Android App</span>
      </div>
      <div className="appsCards__card">
        <img src={Apple} alt="Apple logo" />
        <span>Embed in iOS projects</span>
      </div>
      <div className="appsCards__card">
        <img src={Cpp} alt="Cpp logo" />
        <span>C++ SDKs available</span>
      </div>
      <div className="appsCards__card">
        <img src={Linux} alt="Linux logo" />
        <span>License Linux applications</span>
      </div>
      <div className="appsCards__card">
        <img src={Swift} alt="Swift logo" />
        <span>License projects written in Swift</span>
      </div>
      <div className="appsCards__card">
        <img src={Windows} alt="Windows logo" />
        <span>Use our .NET SDK for Windows applications</span>
      </div>
      <div className="appsCards__card">
        <img src={Java} alt="Java logo" />
        <span>License Java Applications</span>
      </div>
    </div>
  </section>
)
