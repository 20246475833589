import React from "react"
import styled from "styled-components"
import { useEffect } from "react"
// const Table = styled.div`
//   display: flex;
//   flex-direction: column;

//   position: fixed;

//   top: 100px;
//   z-index: 99999;
//   width: 22%;
//   margin: 0 2%;
// `

// const Content = styled.a`
//   border-top-right-radius: 8px;
//   border-bottom-right-radius: 8px;

//   color: #111111;
//   text-decoration: none;
//   text-decoration: bold;
//   font-weight: 700;
//   font-size: 14px;
//   border-left: 6px solid #e44331;
//   padding-left: 8px;
//   ${props =>
//     props.type == "H2" ? `padding-left: 8px;` : `padding-left: 40px;`}
//   margin: 4px 0;
// `

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 16px auto;
  box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
  overflow: hidden;
  animation: shadow 1s alternate infinite;

  @keyframes shadow {
    0% {
      box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.1);
    }
    100% {
      box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.1);
    }
  }
  transition: height 1s, opacity 0.5s, box-shadow 0.9s;
`

const Content = styled.a`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #111111;
  text-decoration: none;
  text-decoration: bold;
  font-weight: 700;
  font-size: 14px;
  padding-left: 8px;
  ${(props) =>
    props.type == "H2" ? `padding-left: 8px;` : `padding-left: 40px;`}
  margin: 4px 0;
  :hover {
    color: #e44331;
  }
`

const Title = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 32px;
  border-radius: 8px;
  font-weight: 800;
  cursor: pointer;
  box-shadow: 0px 0px 20px -12px rgb(228, 67, 49, 1);

  animation: glow 0.7s alternate infinite;

  @keyframes glow {
    0% {
      box-shadow: 0px 0px 20px -12px rgb(228, 67, 49, 1);
    }
    100% {
      box-shadow: 0px 0px 20px -11px rgb(228, 67, 49, 1);
    }
  }
`
function ContentTable({ content, contentType }) {
  useEffect(() => {
    let parent = document.getElementById("sticky").parentElement
    const table = document.getElementById("sticky")
    const title = document.getElementById("title")

    let tableSize = table.clientHeight + "px"

    window.setTimeout(() => {
      tableSize = table.clientHeight + "px"
      // table.style.height = "78px"
      // table.style.opacity = "1"
    }, 1)

    title.addEventListener("click", () => {
      console.log("title")

      if (table.style.height != tableSize) {
        table.style.height = tableSize
        table.style.animation = "none"
      } else {
        table.style.height = "76px"
        table.style.animation = `shadow 1.5s alternate infinite`
      }
    })

    while (parent) {
      const hasOverflow = getComputedStyle(parent).overflow
      if (hasOverflow !== "visible") {
        console.log(hasOverflow, parent)
      }
      parent = parent.parentElement
    }
  }, [])
  let first = 0
  let second = 0

  return (
    <Table id="sticky">
      <Title id="title">Table of Contents:</Title>
      {content?.map((a, i) => {
        if (contentType[i] == "H3") {
          second += 1
        } else {
          first += 1
          second = 0
        }
        return (
          <Content
            type={contentType[i]}
            onClick={(e) => {
              e.preventDefault()
              let element = document.getElementById(a)
              if (element) {
                window.history.pushState(null, null, `#${a}`)
                window.scrollTo({ top: element.offsetTop - 125 })
              }
            }}
          >
            {`${
              contentType[i] == "H2"
                ? first + ". " + a
                : first + "." + second + ". " + a
            }`}
          </Content>
        )
      })}
    </Table>
  )
  //   return <div>ahaaa</div>
}

export default ContentTable
