import React from "react"
import { SocialIcon } from "react-social-icons"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share"

import styled from "styled-components"

const ShareStyle = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: center;
  ${(props) => (props.aling == "left" ? `justify-content: flex-end;` : "")}
  .react-share__ShareButton {
    margin: 2% 8px;
  }

  @media (max-width: 800px) {
    ${(props) => (props.aling == "left" ? `justify-content: flex-start;` : "")}
    margin-left: 0;

    .react-share__ShareButton {
      margin: 0;
      margin-top: 24px;
      transform: scale(0.8);
    }
  }

  @media (max-width: 500px) {
    ${(props) => (props.aling == "left" ? `justify-content: center;` : "")}
    margin: auto;
  }
`

const ShareButtons = ({ url, title, description, aling }) => {
  return (
    <ShareStyle aling={aling}>
      <FacebookShareButton url={url} quote={description}>
        <SocialIcon network="facebook" />
      </FacebookShareButton>

      <LinkedinShareButton url={url} title={title} summary={description}>
        <SocialIcon network="linkedin" />
      </LinkedinShareButton>

      <TwitterShareButton url={url} title={description}>
        <SocialIcon network="twitter" />
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={description}>
        <SocialIcon network="whatsapp" />
      </WhatsappShareButton>

      <EmailShareButton
        subject={title}
        body={`${description}, check full guide here: ${url}`}
      >
        <SocialIcon network="email" />
      </EmailShareButton>
    </ShareStyle>
  )
}

export default ShareButtons
