import React from "react"
import { Link } from "gatsby"
import "./button.scss"

export default (props) => (
  <div
    className={`button__container ${props.className ? props.className : ""}`}
    style={{ zIndex: "99999" }}
  >
    <Link
      to={props.to}
      className={`
            button
            ${props.color ? `button__${props.color}` : ""}
            ${props.size ? `button__${props.size}` : ""}
            ${props.shadow ? `button__${props.color}--shadow` : ""}
            
            `}
      state={props.state}
    >
      {props.label}
    </Link>
    {props.disclaimer ? (
      <span className="button__disclaimer">* No credit card required</span>
    ) : (
      ""
    )}
  </div>
)
