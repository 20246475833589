import React from "react"
import Button from "../Button"
import ArrowLink from "../ArrowLink"

import "./bottomBlock.scss"

export default (props) => (
  <section
    className={`bottomBlock ${
      props.pricingLink ? "bottomBlock--pricingLink" : ""
    }`}
  >
    <div className="container">
      {props.content ? (
        <h2 dangerouslySetInnerHTML={{ __html: props.content }}></h2>
      ) : (
        <h2>
          Enterprise and Start-ups alike <br />
          partner with us to license <br />
          their applications
        </h2>
      )}
      <Button
        to="/get-started/"
        color="blue"
        label={props.button ? props.button : "Open trial account"}
        shadow
        size="big"
      />
      {props.pricingLink ? (
        <ArrowLink to="/pricing/" label="View Pricing" />
      ) : (
        ""
      )}
    </div>
  </section>
)
