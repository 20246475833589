exports.linkResolver = (doc) => {
  // URL for a page type
  if (doc.type === "licensespring_post") {
    return `/preview/blog/${doc.data?.category.toLocaleLowerCase()}/${
      doc.data?.url ? doc.data.url : doc.uid
    }`
  }

  if (doc.type === "page") {
    return `/${doc.data?.url}`
  }

  // Backup for all other types
  return `/${doc.uid}`
}
