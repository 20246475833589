import React from "react"
import { Link } from "gatsby"

import TrustedStars from "../../assets/img/homepage/trusted/5stars.svg"
import TrustedCloud from "../../assets/img/homepage/trusted/cloud.svg"
import TrustedEmail from "../../assets/img/homepage/trusted/email.svg"
import TrustedEnterprise from "../../assets/img/homepage/trusted/enterprise.svg"
import CapterraLogo from "../../assets/img/homepage/trusted/capterra-logo.svg"

import "./trustedBlock.scss"

export default () => (
  <section className="container trustedBlock">
    <h2>Trusted for our Customer Support and our Security</h2>
    <div>
      <img src={TrustedCloud} alt="Cloud illustration" />
      <p>
        99.9% Uptime <br />
        Guarantee under <br />
        <Link to="/service-level-agreement/">our SLA</Link>
      </p>
    </div>
    <div>
      <img src={TrustedEmail} alt="Email illustration" />
      <p>
        Email Support, <br />
        12 hours average <br />
        response time
      </p>
    </div>
    <div>
      <img src={TrustedEnterprise} alt="Enterprise illustration" />
      <p>
        Enterprise-grade <br />
        Security
      </p>
    </div>
    <div>
      <img src={TrustedStars} alt="Stars illustration" />
      <p>
        5/5 on <br />
        <a
          href="https://www.capterra.com/p/178327/License-Spring/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={CapterraLogo} alt="Capterra logo" />
        </a>
      </p>
    </div>
  </section>
)
