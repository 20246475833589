import React from "react"
import { CopyBlock, atomOneLight, dracula } from "react-code-blocks"
import styled from "styled-components"
import { useState } from "react"

const Language = styled.div`
  background: rgb(210, 210, 210);
  ${(props) => props.selected && `background: rgb(235, 235, 235);`}
  color: rgb(56, 58, 66);
  padding: 8px;
  width: max-content;
  margin: 0 2px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
`
const Bar = styled.div`
  display: flex;
  flex-direction: row;
`
function CodeBlocks({ codes, CodeBlockId }) {
  const [selected, setSelected] = useState(0)
  let CodeBlocks = []

  codes.map((val) => {
    if (CodeBlockId == val.id) {
      CodeBlocks.push(val)
    }
  })

  return (
    <div>
      <Bar>
        {CodeBlocks.map((val, index) => {
          if (index == selected) {
            return <Language selected>{val.language}</Language>
          }
          return (
            <Language onClick={() => setSelected(index)}>
              {val.language}
            </Language>
          )
        })}
      </Bar>

      <CopyBlock
        text={CodeBlocks[selected].code.text}
        language={CodeBlocks[selected].language}
        theme={atomOneLight}
        codeBlock
      />
    </div>
  )
}

export default CodeBlocks
