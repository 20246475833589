exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-data-processing-index-js": () => import("./../../../src/pages/data-processing/index.js" /* webpackChunkName: "component---src-pages-data-processing-index-js" */),
  "component---src-pages-eula-generator-jsx": () => import("./../../../src/pages/eula-generator.jsx" /* webpackChunkName: "component---src-pages-eula-generator-jsx" */),
  "component---src-pages-get-started-index-js": () => import("./../../../src/pages/get-started/index.js" /* webpackChunkName: "component---src-pages-get-started-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-education-index-js": () => import("./../../../src/pages/industries/education/index.js" /* webpackChunkName: "component---src-pages-industries-education-index-js" */),
  "component---src-pages-industries-enterprise-index-js": () => import("./../../../src/pages/industries/enterprise/index.js" /* webpackChunkName: "component---src-pages-industries-enterprise-index-js" */),
  "component---src-pages-industries-gaming-vr-index-js": () => import("./../../../src/pages/industries/gaming-vr/index.js" /* webpackChunkName: "component---src-pages-industries-gaming-vr-index-js" */),
  "component---src-pages-industries-isv-index-js": () => import("./../../../src/pages/industries/isv/index.js" /* webpackChunkName: "component---src-pages-industries-isv-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-service-level-agreement-index-js": () => import("./../../../src/pages/service-level-agreement/index.js" /* webpackChunkName: "component---src-pages-service-level-agreement-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-filter-author-js": () => import("./../../../src/templates/blog-filter-author.js" /* webpackChunkName: "component---src-templates-blog-filter-author-js" */),
  "component---src-templates-blog-filter-category-js": () => import("./../../../src/templates/blog-filter-category.js" /* webpackChunkName: "component---src-templates-blog-filter-category-js" */),
  "component---src-templates-blog-filter-featured-js": () => import("./../../../src/templates/blog-filter-featured.js" /* webpackChunkName: "component---src-templates-blog-filter-featured-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

