import React, { useEffect, useState } from "react"
import { render } from "react-dom"
import ReactDOMServer from "react-dom/server"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TrustedBlock from "../components/TrustedBlock"
import BottomBlock from "../components/BottomBlock"
import Apps from "../components/Apps"
import Features from "../components/Features"
import "./page.scss"
import CodeBlocks from "../components/Pages/CodeBlocks"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import Video from "../components/Video"
import Seo from "../components/Seo"
import Accordion from "../components/Accordion/Accordion"
import TextWithImage from "../components/TextWithImage/TextWithImage"
function Java({ data }) {
  // console.log("Page template data", data)
  const page = data.allPrismicPage.nodes[0].data
  const codeBlocks = page?.body[2]?.items
  const faq = page?.body[3]
  // console.log("faq", faq)

  const [cta, setCta] = useState(page.cta)

  let trustedBlock = ReactDOMServer.renderToString(<TrustedBlock />)
  let features = ReactDOMServer.renderToString(
    <Features costume={page.body[1]} />
  )
  let apps = ReactDOMServer.renderToString(<Apps />)
  let CodeBlockId = 1

  useEffect(() => {
    let a = document.getElementsByTagName("a")

    for (var i = 0, l = a.length; i < l; i++) {
      if (a[i].href == "https://licensespring.com/get-started/:button") {
        a[i].href = "https://licensespring.com/get-started/"
        a[i].style.color = "white"
        a[i].parentElement.parentElement.style.display = "flex"
        a[i].parentElement.parentElement.style.justifyContent = "center"
        a[i].parentElement.style.marginBottom = "64px"
        a[i].parentElement.style.marginTop = "32px"

        a[i].classList.add(`button`)
        a[i].classList.add(`button__blue`)
        a[i].classList.add(`button__big`)
        a[i].classList.add(`button__blue--shadow`)
      }
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={page?.seo_title}
        schemaMarkup={page?.schema}
        description={page?.meta_description}
      />
      {page.showhero && (
        <div className="heroBackground" style={{ background: page.hero_color }}>
          {/* <img src={page.hero.url} alt="hero" /> */}
          <GatsbyImage image={getImage(page?.hero)} alt={page?.hero?.alt} />
        </div>
      )}
      <section className="softwareLicensingPage__content">
        <div className="container">
          <div className="flexContainer">
            <h1 className="title">{page.title}</h1>
            {page.body[0].items.map((val) => {
              let asset
              if (val.paragraph_type == "TrustedBlock") asset = trustedBlock
              if (val.paragraph_type == "Features") asset = features
              if (val.paragraph_type == "Sdk") asset = apps
              if (val.paragraph_type == "CodeBlock") {
                let CBI = CodeBlockId
                CodeBlockId += 1
                return (
                  <div className={`Classic`}>
                    <CodeBlocks
                      codes={codeBlocks}
                      CodeBlockId={CBI}
                    ></CodeBlocks>
                  </div>
                )
              }
              if (val.paragraph_type == "video") {
                return (
                  <div className={`video`}>
                    <Video videoSrcURL={val.paragraph.text} videoTitle="test" />
                  </div>
                )
              }

              if (val.paragraph_type == "TextWithImage") {
                //right is default
                const caption = val.caption?.text ?? ""
                const sidePayload = val.side.text ?? undefined
                const sideParams = sidePayload.split(":")

                const side =
                  sideParams.length === 2
                    ? sideParams[0].toLowerCase() === "left"
                      ? "left"
                      : "right"
                    : "right"
                const width =
                  sideParams.length === 2
                    ? parseInt(sideParams[1]) !== NaN
                      ? sideParams[1]
                      : 50
                    : 50

                return (
                  <TextWithImage
                    side={side}
                    width={width}
                    url={val.image.richText[0].url}
                    caption={caption}
                    alt={val.image.richText[0].alt}
                    html={val.paragraph.html}
                  ></TextWithImage>
                )
              }

              return (
                <div
                  className={`${val.paragraph_type}`}
                  dangerouslySetInnerHTML={{
                    __html: asset ? asset : val.paragraph.html,
                  }}
                ></div>
              )
            })}
            {faq && (
              <>
                <h1>{faq.primary.title1.text}</h1>
                <Accordion
                  items={faq.items.map((item) => {
                    return {
                      title: item.question.text,
                      content: item.answer.html,
                    }
                  })}
                ></Accordion>
              </>
            )}
          </div>
        </div>
      </section>
      {cta && (
        <BottomBlock
          pricingLink
          content={page.cta_title}
          button={page.cta_button}
        />
      )}
    </Layout>
  )
}
export default withPrismicPreview(Java)

export const PageQuery = graphql`
  query PageQuery($uid: String!) {
    allPrismicPage(filter: { uid: { eq: $uid } }) {
      nodes {
        id
        data {
          cta
          cta_button
          cta_title
          showhero
          meta_description
          seo_title
          body {
            ... on PrismicPageDataBodyCrissCross {
              id
              items {
                paragraph_type
                paragraph {
                  html
                  text
                }
                side {
                  text
                }
                image {
                  richText
                  html
                }
                caption {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyFeatureCards {
              id
              items {
                icon {
                  url
                }
                color
                desc
                label
              }
            }
            ... on PrismicPageDataBodyCodeblock {
              id
              items {
                code {
                  text
                }
                id
                language
              }
            }
            ... on PrismicPageDataBodyFaq {
              id
              primary {
                title1 {
                  text
                }
              }
              items {
                question {
                  text
                }
                answer {
                  html
                }
              }
            }
          }
          hero_color
          hero {
            gatsbyImageData
            alt
          }
          title
        }
      }
    }
  }
`
