import React from "react"

import Battery from "../../assets/img/homepage/features/battery.svg"
import Calendar from "../../assets/img/homepage/features/calendar.svg"
import Face from "../../assets/img/homepage/features/face.svg"
import Key from "../../assets/img/homepage/features/key.svg"
import Node from "../../assets/img/homepage/features/node.svg"
import Timer from "../../assets/img/homepage/features/timer.svg"
import TrialPerpetual from "../../assets/img/homepage/features/trial_perpetual.svg"

import Arrow from "../../assets/img/arrow.svg"
import "./features.scss"

export default (props) => {
  let card1 = props.costume ? props.costume.items[0] : props.costume
  let card2 = props.costume ? props.costume.items[1] : props.costume
  let card3 = props.costume ? props.costume.items[2] : props.costume
  let card4 = props.costume ? props.costume.items[3] : props.costume
  let card5 = props.costume ? props.costume.items[4] : props.costume
  let card6 = props.costume ? props.costume.items[5] : props.costume
  let card7 = props.costume ? props.costume.items[6] : props.costume

  const features = [
    {
      icon: card1 ? card1.icon.url : TrialPerpetual,
      label: card1 ? card1.label : "Trial / Perpetual",
      desc: card1
        ? card1.desc
        : "Issue licenses to work indefinitely, and set the duration of the trial period",
      color: card1 && card1.color,
    },
    {
      icon: card2 ? card2.icon.url : Timer,
      label: card2 ? card2.label : "Time-Limited",
      desc: card2
        ? card2.desc
        : "Issue licenses that are valid for a set period of time, using either an expiry date, or days remaining model",
      color: card2 && card2.color,
    },
    {
      icon: card3 ? card3.icon.url : Key,
      label: card3 ? card3.label : "Floating",
      desc: card3
        ? card3.desc
        : "Deploy a license management server, or use the LicenseSpring cloud to configure concurrent usage of your software",
      color: card3 && card3.color,
    },
    {
      icon: card4 ? card4.icon.url : Node,
      label: card4 ? card4.label : "Node-Locked",
      desc: card4
        ? card4.desc
        : "Lock a license to a set number of machines by setting max activations, possible due to our device fingerprinting technology",
      color: card4 && card4.color,
    },
    {
      icon: card5 ? card5.icon.url : Calendar,
      label: card5 ? card5.label : "Subscription",
      desc: card5
        ? card5.desc
        : "Synchronize the validity of your license with the subscription period of your billing cycle",
      color: card5 && card5.color,
    },
    {
      icon: card6 ? card6.icon.url : Battery,
      label: card6 ? card6.label : "Consumption",
      desc: card6
        ? card6.desc
        : "License according to a pay-per-use model, which can be cyclically reset",
      color: card6 && card6.color,
    },
    {
      icon: card7 ? card7.icon.url : Face,
      label: card7 ? card7.label : "User-Based",
      desc: card7
        ? card7.desc
        : "You have the option to either issue license keys, or set usernames and passwords for your licenses",
      color: card7 && card7.color,
    },
    {
      label: "View License Model Docs",
    },
  ]
  const Feature = features.map((feature) => (
    <div className="feature" key={feature.label}>
      {feature === features[features.length - 1] ? (
        <div className="feature__inner">
          <div>
            <a href="https://docs.licensespring.com/docs/license-models">
              <h3>
                View Licenses Models Docs
                <img src={Arrow} alt="Arrow icon" />
              </h3>
            </a>
          </div>
        </div>
      ) : (
        <div className="feature__inner">
          <div style={{ background: feature.color ? feature.color : "" }}>
            {feature.icon && (
              <img src={feature.icon} alt={`${feature.label} icon`} />
            )}
            <h3>{feature.label && feature.label}</h3>
          </div>
          <span style={{ background: feature.color ? feature.color : "" }}>
            {feature.desc && feature.desc}
          </span>
        </div>
      )}
    </div>
  ))

  return (
    <section className="featuresComponent">
      {props.title ? (
        <h2>Support flexible packaging, bundling and feature monetization.</h2>
      ) : (
        ""
      )}
      {props.content ? (
        <p>
          LicenseSpring offers flexible usage rights for your software. All of
          our licenses can be node-locked, reset, disabled, and modified once
          they have been issued to end-users. Most license models can even be
          used in offline environments.
        </p>
      ) : (
        ""
      )}

      {Feature}
    </section>
  )
}
