import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Seo from "../components/Seo"
import AuthorCard from "../components/blog/authorCard"
import { Breadcrumbs } from "../components/blog/breadcrumbs"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import BlogCard from "../components/blog/blogCard"
// import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import ShareButtons from "../components/blog/sharebuttons"
import Check from "../assets/img/blog/check.svg"
import Layout from "../components/Layout"
import CodeBlocks from "../components/Pages/CodeBlocks"
import ContentTable from "../components/blog/contentTable"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
// import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter"
// import { dark } from "react-syntax-highlighter/dist/esm/styles/prism"

import "./blog.scss"
import Accordion from "../components/Accordion/Accordion"
import TextWithImage from "../components/TextWithImage/TextWithImage"

const Blog = styled.div`
  display: flex;
  flex-direction: column;
  color: #111111;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  max-width: 1440px;
  margin: auto;

  .block-img {
    display: flex;
    justify-content: center;
  }
  h3 {
    color: rgb(47, 47, 47);
    font-size: 19px;
    font-weight: 700;
  }
  h1 {
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 40px;
    color: rgb(19, 176, 252);
    font-weight: 700;
  }
  h6 {
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
    font-size: 13px;
  }
  h2 {
    text-align: left;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 24px;
    color: rgb(19, 176, 252);
    font-weight: 700;
  }

  ul {
    margin-top: 16px;
    margin-left: 16px;

    li {
      margin-bottom: 16px;
      margin-left: 16px;
    }
    li::before {
      content: "✓";
      color: none;
      /* color: #13b0fc; */
      font-size: 0;
      font-weight: bold;
      /* margin-right: 0.3rem; */
      /* margin: 0 8px; */
      position: absolute;
      display: inline-block;
      height: 14px;
      width: 7px;
      border-bottom: 2.5px solid #13b0fc;
      border-right: 2.5px solid #13b0fc;
      transform: translate(-18px, 0) rotate(50deg);
    }
  }

  p {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .vector {
    width: 100px;
  }

  .body {
    a {
      cursor: pointer;
      color: #13b0fc;
      :hover {
        text-decoration: underline;
      }
    }
  }
`
const Div = styled.div`
  text-align: left;
  margin: auto;
  width: 90%;
  transition: width 1s;
  @media (max-width: 980px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 92%;
  }

  /* p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  } */
`
const DivRelated = styled.div`
  text-align: left;
  margin: auto;
  width: 100%;
`

const Hero = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  padding: 1%;
  margin-bottom: 32px;

  img {
    width: 100%;
    max-height: 240px;
    /* max-width: 450px; */
    height: auto;
    margin: auto;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  gap: 5% 5%;

  a {
    text-decoration: none;
  }
  /* align-items: center;
  justify-content: center; */
`

const RelatedBlogPosts = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #111111;
  margin: 50px 0;
`

const Related = styled.div`
  background: #fbfbfb;
  border-top: 1px solid #e1e1e1;
  width: 100%;
`

const PublishDate = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`

const PublishAuthor = styled.div`
  width: 100%;
  text-align: left;
  a {
    color: #111111;
    text-decoration: none;
    font-weight: 800;
  }
`

const CardPreview = styled.div`
  width: 90%;
  height: 1000px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: breath-animation;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin: 32px auto;
  h2 {
    width: 100%;
    text-align: center;
  }

  @keyframes breath-animation {
    0% {
      width: 580px;
    }

    25% {
      width: 580px;
    }

    75% {
      width: 280px;
    }

    100% {
      width: 280px;
    }
  }
`

const SocialRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  align-items: flex-end;
  margin-bottom: 32px;
  margin-top: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
`

const Title = styled.h1`
  width: 80%;
  margin: 32px auto;
  text-align: left;
`

const BlogPost = ({ data, location }) => {
  console.log(data)
  let { post } = data
  let { prev } = data
  let { next } = data
  let { related1 } = data
  let { related2 } = data
  let { site } = data

  // console.log(post?.nodes[0].data)
  post = post?.nodes[0].data
  prev = prev?.nodes[0]
  next = next?.nodes[0]
  related1 = related1?.nodes[0]
  related2 = related2?.nodes[0]
  let codeBlockId = 1

  //assign blogpost slices
  let codes
  let slices = []
  post.body1.forEach((item) => {
    const { slice_type } = item

    switch (slice_type) {
      case "faq":
        slices.push(item)
        break
      case "codeblock":
        codes = item
        break
      case "page_component":
        slices.push(item)
        break
      case "text_with_image":
        slices.push(item)
        break

      default:
        break
    }
  })

  // console.log("faq", faq)
  // console.log("codes", codes)

  useEffect(() => {
    function offsetAnchor() {
      let hash = decodeURI(window.location.hash.replace("#", ""))
      let target = document.getElementById(hash)

      if (target != null) {
        if (location.hash.length !== 0) {
          console.log(hash, ":", target)
          window.setTimeout(() => {
            window.scrollTo(window.scrollX, target.offsetTop - 100)
          }, 1)
        }
      }
    }

    /// Scroll on landing
    window.setTimeout(offsetAnchor, 1)

    /// Popup logic
    const aElements = document.getElementsByTagName("a")

    for (var i = 0, l = aElements.length; i < l; i++) {
      var el = aElements[i]

      if (el.href.includes("get-started-button")) {
        el.href = "/get-started/"
        el.classList.add("button")
        el.classList.add("button__blue")
        el.style.color = "white"
        el.parentElement.style.display = "flex"
        el.parentElement.style.justifyContent = "center"
        el.parentElement.style.margin = "24px 0"
        el.style.textDecoration = "none"
      }
    }
    console.log("data: ", data)
  }, [])

  const body = data.prismicLicensespringPost
    ? data.prismicLicensespringPost?.data.body.html.split("<pre></pre>")
      ? data.prismicLicensespringPost.data.body.html.split("<pre></pre>")
      : [data.prismicLicensespringPost?.data.body.html]
    : post.body.html.split("<pre></pre>")
    ? post.body.html.split("<pre></pre>")
    : [post.body.html]

  console.log("body:", body)

  if (!post) post = data.prismicLicensespringPost.data

  const url = site?.siteMetadata.siteUrl + location.pathname
  const description = post.description ? post.description : "Blog post"
  // console.log(SyntaxHighlighter.supportedLanguages)

  const [tableContent, setTableContent] = useState()
  const [tableContentType, setTableContentType] = useState()

  function GenerateToC(element) {
    let content = []
    let contentType = []
    for (var i = 0, l = element.children.length; i < l; i++) {
      if (element.children[i] != undefined) {
        let childrenElement = GenerateToC(element.children[i])
        content = [...content, ...childrenElement.content]
        contentType = [...contentType, ...childrenElement.contentType]
      }
      if (
        //Which elements should be included
        element.children[i].nodeName == "H2"
      ) {
        element.children[i].id = element.children[i].textContent
        content.push(element.children[i].textContent)
        contentType.push(element.children[i].nodeName)
      }
    }
    return { content: content, contentType: contentType }
  }

  useEffect(() => {
    const bEl = document.getElementById("#id")
    let ToC = GenerateToC(bEl)

    setTableContent((t) => ToC.content)
    setTableContentType((t) => ToC.contentType)
  }, [])

  return (
    <Layout>
      <Blog>
        <Seo
          title={post?.title}
          schemaMarkup={post?.schema}
          description={post?.description}
        />
        <Div>
          <Breadcrumbs
            currentPath={["/"].concat(
              location.pathname.split("/").filter((name) => name !== "")
            )}
            customUrl={post?.title}
          />
        </Div>
        <Div>
          {data?.prismicLicensespringPost && (
            <CardPreview>
              <h2>Card Preview: </h2>
              <BlogCard
                post={{ data: post }}
                author={post.author.document.data.name}
                visible={"flex"}
                margin={"auto"}
              />
            </CardPreview>
          )}
          <Title>{post?.h1}</Title>
          <SocialRow>
            <div style={{ width: "100%" }}>
              <PublishDate>Published on: {post?.publish_date}</PublishDate>
              {post?.last_updated ? (
                <PublishDate>Last updated: {post?.last_updated}</PublishDate>
              ) : null}
              <PublishAuthor>
                by{" "}
                <Link
                  to={`/blog/${post.author.document?.data.name.replaceAll(
                    " ",
                    "-"
                  )}`}
                >
                  {post.author.document?.data.name}
                </Link>
              </PublishAuthor>
            </div>
            <ShareButtons
              url={url}
              title={post.title}
              description={description}
              aling={"left"}
            />
          </SocialRow>
          <Hero image={getImage(post?.hero)} alt={post?.hero?.alt} />
          <ContentTable content={tableContent} contentType={tableContentType} />

          <div id="#id">
            {body.map((val, index) => {
              let CBI = codeBlockId
              codeBlockId += 1
              return (
                <>
                  <Div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: val }}
                  />
                  {index + 1 != body.length && (
                    <Div>
                      <CodeBlocks
                        codes={codes.items}
                        CodeBlockId={CBI}
                      ></CodeBlocks>
                    </Div>
                  )}
                </>
              )
            })}

            {slices.map((slice) => {
              const { slice_type } = slice

              switch (slice_type) {
                case "page_component":
                  return slice.items.map((item) => {
                    return (
                      <Div
                        className="body"
                        dangerouslySetInnerHTML={{
                          __html: item.paragraph.html,
                        }}
                      />
                    )
                  })

                case "faq":
                  return (
                    <div className="faqContainer">
                      <h2>{slice.primary.title1.text}</h2>
                      <Accordion
                        items={slice.items.map((item) => {
                          return {
                            title: item.question.text,
                            content: item.answer.html,
                          }
                        })}
                      ></Accordion>
                    </div>
                  )

                case "text_with_image":
                  return (
                    <Div>
                      {slice.items.map((item) => {
                        const caption = item.caption.text ?? ""
                        const image = item.image.url
                        const alt = item.image.alt
                        const side = item.image.text
                          ? item.image.text.toLowerCase() === "left"
                            ? "left"
                            : "right"
                          : "right"
                        const width = item.width.text
                          ? parseInt(item.width.text) !== NaN
                            ? item.width.text
                            : 50
                          : 50
                        const paragraph = item.paragraph.html

                        return (
                          <TextWithImage
                            alt={alt}
                            caption={caption}
                            html={paragraph}
                            side={side}
                            url={image}
                            width={width}
                          ></TextWithImage>
                        )
                      })}
                    </Div>
                  )

                default:
                  break
              }
            })}
          </div>

          <ShareButtons
            url={url}
            title={post.title}
            description={description}
          />
        </Div>
      </Blog>
      {post?.author.document?.data && (
        <AuthorCard
          posts={post?.author}
          author={post?.author.document.data.name}
          bio={true}
        />
      )}

      <Related>
        <RelatedBlogPosts>Related Blog Posts </RelatedBlogPosts>

        <DivRelated>
          <Flex>
            {prev && !related1 && (
              <BlogCard
                post={prev}
                author={prev.data.author.document?.data.name}
                visible={"flex"}
              />
            )}
            {next && !related2 && (
              <BlogCard
                post={next}
                author={next.data.author.document?.data.name}
                visible={"flex"}
              />
            )}
            {related1 && (
              <BlogCard
                post={related1}
                author={related1.data.author.document?.data.name}
                visible={"flex"}
              />
            )}
            {related2 && (
              <BlogCard
                post={related2}
                author={related2.data.author.document?.data.name}
                visible={"flex"}
              />
            )}
          </Flex>
        </DivRelated>
      </Related>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery(
    $uid: String!
    $prev: String!
    $next: String!
    $related1: String!
    $related2: String!
  ) {
    post: allPrismicLicensespringPost(filter: { uid: { eq: $uid } }) {
      nodes {
        _previewable
        data {
          h1
          body {
            html
          }
          body1 {
            ... on PrismicLicensespringPostDataBody1Codeblock {
              slice_type
              items {
                code {
                  text
                }
                id
                language
              }
            }
            ... on PrismicLicensespringPostDataBody1Faq {
              slice_type
              primary {
                title1 {
                  text
                  html
                }
              }
              items {
                question {
                  text
                }
                answer {
                  html
                }
              }
            }
            ... on PrismicLicensespringPostDataBody1PageComponent {
              slice_type
              items {
                paragraph {
                  html
                }
              }
            }
            ... on PrismicLicensespringPostDataBody1TextWithImage {
              slice_type
              items {
                caption {
                  text
                }
                image {
                  url
                  alt
                }
                paragraph {
                  html
                }
                side {
                  text
                }
                width {
                  text
                }
              }
            }
          }
          schema
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          description
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  facebook
                  image {
                    gatsbyImageData
                    alt
                  }
                  linkedin
                  name
                  title
                  twitter
                  shortbio {
                    text
                  }
                }
              }
            }
          }
          hero {
            gatsbyImageData
            alt
          }
          title
        }
        uid
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    prev: allPrismicLicensespringPost(filter: { uid: { eq: $prev } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    next: allPrismicLicensespringPost(filter: { uid: { eq: $next } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related1: allPrismicLicensespringPost(filter: { uid: { eq: $related1 } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related2: allPrismicLicensespringPost(filter: { uid: { eq: $related2 } }) {
      nodes {
        _previewable
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`

export default withPrismicPreview(BlogPost)
