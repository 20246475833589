import React from "react"
import { Link } from "gatsby"

import Arrow from "../../assets/img/arrow.svg"
import ArrowWhite from "../../assets/img/arrowWhite.svg"

import "./arrowlink.scss"

export default (props) =>
  props.anchor ? (
    <a
      {...props}
      href={props.to}
      className={`arrowLink ${props.color ? `arrowLink--${props.color}` : ""} 
    ${props.className ? props.className : ""}`}
    >
      {props.label}
      {props.color === "white" ? (
        <img src={ArrowWhite} alt="Arrow icon" />
      ) : (
        <img src={Arrow} alt="Arrow icon" />
      )}
    </a>
  ) : (
    <Link
      to={props.to}
      className={`arrowLink ${props.color ? `arrowLink--${props.color}` : ""} 
    ${props.className ? props.className : ""}`}
    >
      {props.label}
      {props.color === "white" ? (
        <img src={ArrowWhite} alt="Arrow icon" />
      ) : (
        <img src={Arrow} alt="Arrow icon" />
      )}
    </Link>
  )
