import React from "react"
import { Link } from "gatsby"
import "./footer.scss"
import Logo from "../../assets/img/logo--white.svg"

export default () => (
  <footer className="footer">
    <div className="container row">
      <div className="col col__md-50 col__lg-25">
        <h2>Legal</h2>
        <Link to="/service-level-agreement/">Service Level Agreement</Link>
        <Link to="/privacy/">Privacy Policy</Link>
        <Link to="/cookies/">Cookies Policy</Link>
        <Link to="/terms-of-service/">Terms of Service</Link>
        <Link to="/data-processing/">Data Processing</Link>
        <Link to="/eula-generator/">EULA Generator</Link>
      </div>
      <div className="col col__md-50 col__lg-25">
        <h2>Documentation</h2>
        <a
          href="https://docs.licensespring.com/docs/getting-started"
          target="_blank"
        >
          Getting Started
        </a>
        <a href="https://docs.licensespring.com/docs/sdks" target="_blank">
          SDKs
        </a>
        <a
          href="https://docs.licensespring.com/docs/license-api"
          target="_blank"
        >
          License API
        </a>
        <a
          href="https://docs.licensespring.com/docs/management-api"
          target="_blank"
        >
          Management API
        </a>
        <a href="https://docs.licensespring.com/docs/changelog" target="_blank">
          Platform Changelog
        </a>
        <Link to="/pricing/">Pricing</Link>
        <Link to="/blog/">Blog</Link>
      </div>
      <div className="col col__md-50 col__lg-25">
        <h2>Address</h2>
        <p>
          555 Burrard St,
          <br />
          Vancouver, BC V7X 1M8,
          <br />
          Canada
        </p>
      </div>
      <div className="col col__md-50 col__lg-25">
        <h2>Company</h2>
        <Link to="/about-us/">About Us</Link>
        <a
          href="https://licensespring.zendesk.com/hc/en-us/requests/new"
          target="_blank"
        >
          Contact Support
        </a>
        <Link to="/get-started/">Contact Sales</Link>
        <p>Tel: +1 (604) 345-7054</p>
      </div>
      <div className="col col__md-50">
        <img src={Logo} alt="Footer logo" className="footer__logo" />
        <p>LicenseSpring® is a Registered Trademark of Cense Data Inc.</p>
        <p>{new Date().getFullYear()} © Cense Data Inc. All rights reserved.</p>
      </div>
      <div className="col footer__disclaimer col__md-50 col__self-end text__lg-right"></div>
    </div>
  </footer>
)
